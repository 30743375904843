.landing-page {
    /* background-color: palegreen; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
}

.text-container {
    max-width: 500px;
    max-width: 50%;
    /* width: 100%; */
    align-items: center;
    align-content: center;
    text-align: left;
    /* background-color: white; */
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 30px;
}

.innerbox {
    /* display: flex; */
    /* flex-direction: column; */
    /* background-color: #fefdfe; */
    /* padding: 1rem; */
    /* border-radius: 10px; */
    /* box-shadow: 4px 4px 0px rgba(0, 0, 0, 1); */
}

.tweet-image {
    /* margin-top: -200px; */
    width: 40%;
    max-width: 700px;
    min-width: 320px;
    background-color: white;
    border-radius: 30px;
}

.landing-page p {
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    font-weight: bold;
    /* text-align: center; */
    /* line-height: 1.8; /* Increased line-height value */
}

.button {
    padding: 0.3rem 1rem; /* Reduced vertical padding */
    background-color: whitesmoke;
    color: black;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    margin-top: 10px; /* Added vertical margin */
    margin-bottom: 10px; /* Added vertical margin */
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
}

.submit-button {
    padding: 0.3rem 1rem; /* Reduced vertical padding */
    background-color: darkslategray;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    margin-top: 10px; /* Added vertical margin */
    margin-bottom: 10px; /* Added vertical margin */
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
}

.file-input {
    display: none;
}

.thank-you-screen {
    background-color: #fefdfe;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thank-you-screen h1 {
    font-size: clamp(2rem, 4vw, 3rem);
    font-weight: bold;
    color: black;
    line-height: 1.5; /* Added line-height for h1 */
}

.waiting-screen {
    background-color: #fefdfe;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.waiting-gif {
    width: 100%;
    max-width: 800px;
}
